import * as React from 'react';

const parseQuery = (q) => {
  if (q === 'mobile') {
    return '(max-width: 719px)';
  } else if (q === 'desktop') {
    return '(min-width: 1025px)';
  }

  return q;
};

const useMedia = (rawQuery, defaultState = false) => {
  const [state, setState] = React.useState(defaultState);
  const query = parseQuery(rawQuery);

  React.useEffect(() => {
    let mounted = true;
    const mql = window.matchMedia(query);

    const onChange = () => {
      if (!mounted) {
        return;
      }

      setState(!!mql.matches);
    };

    mql.addListener(onChange);
    setState(mql.matches);

    return () => {
      mounted = false;
      mql.removeListener(onChange);
    };
  }, [query]);

  return state;
};

export default useMedia;
